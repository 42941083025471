import React, { useEffect } from 'react';
import './assets/css/styles2.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Route from './routes/Route'

// import { BottomSheetWatchlist } from './screens/private/watchlist/BottomSheetWatchlist';
// sass --watch src/assets/scss/main.scss:src/assets/css/styles2.css
import { useDispatch, useSelector } from "react-redux";
import { fetchPortfolio } from './store/portfolio/action';
import { fetchCurrencyRates } from './store/currencyRate/action';
import { fetchIndexes } from './store/indexBox/action';
import { fetchProfile } from './store/profile/action';
import { fetchOrder, fetchTrades } from './store/order/action';
import { toast } from 'react-toastify';
// import ReactPixel from 'react-facebook-pixel';

// ReactPixel.init('2093474854359345');

function App() {
  const loginState = useSelector((state) => state.login);
  const socketState = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  useEffect(() => {
    if (loginState.jwttoken) {
      dispatch(fetchIndexes(loginState.jwttoken));
      dispatch(fetchCurrencyRates(loginState.jwttoken));
      dispatch(fetchPortfolio(loginState.jwttoken));
      dispatch(fetchProfile(loginState.jwttoken));
      setTimeout(() => {
        socketState.notifySocket.emit("authenticate", loginState.jwttoken);
        socketState.notifySocket.on("notify", (data) => {
          if (data.visible === "yes") {
            if (data.type === "success") {
              toast.success(data.message)
            } else {
              toast.error(data.message);
            }
          }
          dispatch(fetchPortfolio(loginState.jwttoken));
          dispatch(fetchOrder(loginState.jwttoken));
          dispatch(fetchTrades(loginState.jwttoken));
        });
      }, 2000)
    }
  }, []);

  // React.useEffect(() => {
  //   ReactPixel.pageView();
  // }, []);

  return (
    <div className="app mb-0">
      {/* <BottomSheetWatchlist /> */}
      <Route />
    </div>
  );
}

export default App;
